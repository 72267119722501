import * as React from "react";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";

import MashConfig from "../../mash-config";

export default function BoostButton() {
  return (
    <>
      <Helmet>
        <script async src="https://components.getmash.com/boost/boost.js"></script>
      </Helmet>
      <mash-boost-button
        icon={MashConfig.boosts.icon}
        layout-mode={MashConfig.boosts.layoutMode}
        float-location={isMobile ? MashConfig.boosts.floatLocationMobile : MashConfig.boosts.floatLocationDesktop}
        variant={MashConfig.boosts.variant}
      ></mash-boost-button>
    </>
  );
}
